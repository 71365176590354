import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../components/Layout';
import PromotionList from '../../components/PromotionList';
import EnterPromotionCode from '../../components/EnterPromotionCode';
import { getPromotions } from '../../actions/promotion';
import { createLoadingSelector } from '../../reducers/api';
import { createPromotionsSelector } from '../../reducers/promotion';

export default ({}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector(['promotion/get']));
  const promotionList = useSelector(createPromotionsSelector);

  React.useEffect(() => {
    dispatch(getPromotions());
  }, []);

  let promotions = promotionList || [];

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>โปรโมชั่น - MorOnline</title>
      </Helmet>
      <EnterPromotionCode />
      <PromotionList isLoading={isLoading} promotionList={promotionList} />
    </Layout>
  )
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { getPromotions } from '../../actions/promotion';
import * as Api from '../../apis';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchPromotion, setSearchPromotion] = React.useState(null);

  const onClickSearch = () => {
    Api.postSearchandCollectPromotionAppointment({code: searchPromotion}).then((data) => {
      alert('เก็บโค้ดโปรโมชั่นสำเร็จ');
      dispatch(getPromotions());
    }).catch(message => {
      alert('ไม่พบโค้ดนี้ โค้ดอาจถูกยกเลิก หรือใส่ไม่ถูกต้อง กรุณาลองอีกครั้ง');
    });
    setSearchPromotion('');
  }

  const handleInputSearch = (e) => {
    setSearchPromotion(e.target.value)
  }

  return (
    <Box p={2}>
      <Grid container direction="row" justifyContent="center">
        <Grid item className={classes.root}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="กรอกโค้ดโปรโมชั่น"
            size="small"
            onChange={handleInputSearch}
            value={searchPromotion}
          />
        </Grid>
        <Grid item style={{ display: 'flex', marginLeft: 3 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={searchPromotion ? false : true}
            onClick={onClickSearch}
          >
            เก็บโค้ด
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
import * as Constant from './constants';
import * as Api from '../apis';

export const setPromotion = id => ({
  type: Constant.SET_PROMOTION,
  payload: id,
});

export const removePromotion = id => ({
  type: Constant.REMOVE_PROMOTION,
  payload: id,
});

export const getPromotions = (filter=false) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.PROMOTION_GET_REQUEST });
    return Api.getListPromotionAppointment()
      .then(payload => {
        if (filter) {
          const { session } = getState();
          let member = session.user.is_member;
          let promotions;
          promotions = payload.results.filter(
            val => val.promotion.is_from_membership && val.promotion.available,
          );
          let coupon =
            promotions.length > 0 ? promotions[0]['promotion'].id : null;

          dispatch({ type: Constant.SET_PROMOTION, payload: coupon });
        }
        dispatch({ type: Constant.PROMOTION_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.PROMOTION_GET_FAILED, message });
      });
  };
};

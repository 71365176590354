import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getPromotions } from '../../actions/promotion';
import { createLoadingSelector } from '../../reducers/api';
import { createPromotionsSelector } from '../../reducers/promotion';
import { Avatar, Box, Button, Card, CardContent, Chip, Grid, Typography } from '@material-ui/core';
import { Loyalty } from '@material-ui/icons';
import LoaderView from '../../components/LoaderView';
import PromotionDialog from '../PromotionDialog';

const useStyles = makeStyles(theme => ({
  image: {
    width: 50,
    height: 50,
  },
  media: {
    paddingTop: '56.25%'
  },
  card: {
    margin: 'auto',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)'
    }
  },
  content: {
    textAlign: 'left',
  },
  promotionName: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    flexGrow: 1,
  },
}));

export default ({ onSelectPromotion, filter, isLoading, promotionList, readOnly = true}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPromotion, setShowPromotion] = useState();
  // const today = new Date();
  // const currentDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')

  
  let promotions = promotionList || [];

  if (!isLoading && promotionList.length >= 0) {
    promotions = promotionList.filter(val => val.promotion.available === true);
    if (filter) {
      promotions = promotions.filter(val => val.promotion.use_for.includes(filter));
    }
  };

  return (
    <LoaderView
      loading={isLoading}
      empty={!isLoading && promotionList.length === 0 || promotions.length === 0}
      emptyText='คุณยังไม่มีโค้ดส่วนลด'
    >
      <PromotionDialog
        promotionId={showPromotion}
        open={!!showPromotion}
        closeDialog={() => setShowPromotion(null)}
      />
      <Box p={2}>
        <h3>ส่วนลดของฉัน</h3>
        {
          promotions.map((item, index) => {
            let useList = [];
            if (item.promotion.use_for.includes('appointment')) {
              useList.push('ปรึกษาแพทย์')
            } if (item.promotion.use_for.includes('lab')) {
              useList.push('แล็บ')
            } if (item.promotion.use_for.includes('shop')) {
              useList.push('ยา')
            }
            let useFor = useList.join(', ');
            // TODO: check for expiration
            // let checkedDate = '#4677a8';
            // let expired = false;
            // if (Date.parse(currentDate) > Date.parse(item.promotion.end_date)) {
            //   checkedDate = '#BEBEBE';
            //   expired = true
            // }
            return (
              <Card
                elevation={0}
                key={index}
                onClick={() => setShowPromotion(item.promotion.id)}
                aria-orientation='horizontal'
                style={{ marginBottom: 4 }}
              >
                <CardContent className={classes.content}>
                  <Grid container direction='row' justifyContent='flex-start' wrap='nowrap'>
                    <Grid item style={{ display: 'flex' }} alignItems='center'>
                      <Avatar className={classes.image}><Loyalty /></Avatar>
                    </Grid>
                    <Grid item className={classes.promotionName}>
                      <Box ml={1}>
                        <Typography variant='h6'>
                          {item.promotion.name}
                        </Typography>
                        <Typography variant='caption' style={{ color: '#708090' }}>
                          {useFor ? `ใช้ได้กับ: ${useFor}` : null} ใช้ได้ก่อน: {item.promotion.end_date}
                        </Typography>
                      </Box>
                    </Grid>
                    {
                      !readOnly && (
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                          <Button variant='contained' color='primary' onClick={() => onSelectPromotion(item.promotion.id)}>ใช้</Button>
                        </Grid>
                      )
                    }
                    {/* {
                          expired && (
                            <Grid item style={{ display: 'flex' }}>
                              <Typography variant='contained'>หมดเวลา</Typography>
                            </Grid>
                          )
                        } */}
                  </Grid>
                </CardContent>
                <span></span>
              </Card>
            )
          })
        }
      </Box>
    </LoaderView>
  )
};